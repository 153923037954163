import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import '../style/table.css'
import axios from 'axios'
class Table extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      editing: '',
      masterSelect: false,
      tempData: this.props.data,
      editorName: '',
      initAmount: 0,
      historyList: this.props.historyList,
      isDisabled: false,
      addOrRemoveUser: '',
      warningMsg: '',
      successMsg: '',
    }
    // this.changeMasterSelect = this.changeMasterSelect.bind(this);
  }

  componentDidMount() {
    console.log(this.state.tempData)

  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({ tempData: this.props.data });
    }
    if (this.props.historyList !== prevProps.historyList) {
      this.setState({ historyList: this.props.historyList });
    }
  }
  handleEdit(id) {
    this.setState({ editing: id })
  };

  handleSave(id) {
    this.setState({ editing: '' })
  }

  setAmount(id, name, value) {
    console.log(name, value)
    if (!this.state.editorName) {
      this.setState({ editorName: name, initAmount: this.getAmountByName(name) })
    }

    if (value === "")
      value = 0;
    this.setState(prevState => ({
      tempData: prevState.tempData.map(item => {
        if (item._id === id) {
          return { ...item, amount: value };
        }
        return item;
      })
    }))
  }

  changeSelected(event, id) {
    const { checked } = event.target;
    this.setState(prevState => ({
      tempData: prevState.tempData.map(item => {
        if (item._id === id) {
          return { ...item, selected: checked };
        }
        return item;
      })
    }))
  }

  changeMasterSelect = (event) => {
    this.setState({ masterSelect: event.target.checked })
    this.setState(prevState => ({
      tempData: prevState.tempData.map((item) => ({
        ...item,
        selected: event.target.checked,
      })),
    }));
  }

  calculateSplit = async (isManualEdit) => {
    if (isManualEdit) return;
    // let paymentPerParticipant = Math.ceil(this.props.paymentAmount / this.countParticipants());
    let paymentPerParticipant = this.props.paymentAmount / this.countParticipants();
    return await this.setState(prevState => ({
      tempData: prevState.tempData.map(item => {
        console.log(typeof item.amount, typeof this.props.paymentAmount, typeof paymentPerParticipant)
        if (item.name === this.props.paymentName && item.selected)
          return { ...item, amount: Number((parseFloat(item.amount) + this.props.paymentAmount - paymentPerParticipant).toFixed(2)) }
        if (item.name === this.props.paymentName)
          return { ...item, amount: Number((parseFloat(item.amount) + this.props.paymentAmount).toFixed(2)) }
        if (item.selected)
          return { ...item, amount: Number((parseFloat(item.amount) - paymentPerParticipant).toFixed(2)) }
        return item;
      })
    }))
  }
  countParticipants = () => {
    let countParticipants = 0;
    this.state.tempData.map(item => {
      if (item.selected)
        countParticipants++;
    })
    return countParticipants;
  }

  getAmountByName = (name) => {
    const item = this.state.tempData.find((item) => item.name === name);
    return item ? item.amount : 0;
  }

  getSelectedNames = () => {
    let selectedNames = ''
    let hasSelected = false
    this.state.tempData.map(item => {
      if (item.selected) {
        hasSelected = true
        selectedNames += `${item.name}, `;
      }
    })
    return selectedNames;
  }
  setDateFormat = () => {
    const date = new Date();
    const options = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    };
    const formattedDate = date.toLocaleString('en-GB', options);
    return ` ב: ${formattedDate}`
  }
  submitData = (isManualEdit) => {
    this.setState({ isDisabled: true })
    this.calculateSplit(isManualEdit).then(() => {
      let dataToSend = this.state.tempData
      let updateRow;
      let isUpdated = true;
      if (isManualEdit) {
        let finalAmount = this.getAmountByName(this.state.editorName)
        isUpdated = this.state.initAmount !== finalAmount
        updateRow = `עריכה: ${this.state.editorName}, לפני: ${this.state.initAmount} אחרי: ${finalAmount}`
      } else {
        // isUpdated = this.countParticipants() >0;
        updateRow = `חלוקה: שולם ע"י: ${this.props.paymentName} קניה בסך:${this.props.paymentAmount}, משתתפים:${this.getSelectedNames()}`
      }

      updateRow += `${this.setDateFormat()}`
      if (!isUpdated)
        return;
      axios.post('https://split-bill-server.onrender.com/update', { dataToSend, updateRow }).then(response => {
        let data = response.data;
        this.setState({ editorName: '', initAmount: 0, historyList: data.historyList })

        setTimeout(() => {
          this.setState({ isDisabled: false })
        }, 2000)
      }).catch(err => {
        console.log(err);
      })
    });
  }

  addOrRemoveUserInput = (e) => {
    this.setState({ addOrRemoveUser: e.target.value, warningMsg: '', successMsg: '' })
  }

  addOrRemoveUser = (action) => {
    this.setState({ warningMsg: '', successMsg: '' })
    const name = this.state.addOrRemoveUser
    if (!name) {
      this.setState({ warningMsg: '!יש לכתוב שם' })
      return;
    }

    axios.get(`https://split-bill-server.onrender.com/${action}/${name}`).then(response => {
      let data = response.data.result;
      data.status === 'success' ? this.setState({ successMsg: data.message }) : this.setState({ warningMsg: data.message })
    }).catch(err => {
      console.log(err);
    })
  }

  getTotalBalance = () => {
    let total = 0;

    this.state.tempData.forEach(element => {
      total += parseFloat(element.amount);
    });
    return Math.abs(total) < 0.01 ? "0.00" : total.toFixed(2);
  }

  render() {
    const { masterSelect, tempData, editing, isDisabled, addOrRemoveUser, historyList, warningMsg, successMsg } = this.state
    return (
      <>
        <table className="table">
          <thead>
            <tr>
              <th><input type="checkbox" checked={masterSelect} onChange={this.changeMasterSelect} /></th>
              <th>סכום</th>
              <th>שם</th>
            </tr>
          </thead>
          <tbody>
            {(tempData).map((item) => (
              <tr key={item._id}>
                <td><input type="checkbox" onChange={e => this.changeSelected(e, item._id)} checked={item.selected} /></td>
                <td>
                  {editing === item._id ? (
                    <input
                      type="text"
                      value={item.amount}
                      onChange={(e) => this.setAmount(item._id, item.name, e.target.value)}
                      placeholder={item.amount}
                      onBlur={() => this.submitData(true)}
                    />
                  ) : (
                    <span onClick={() => { if (this.props.readOnly) this.handleEdit(item._id) }}>{item.amount}</span>
                  )}
                </td>
                <td>{item.name}</td>
              </tr>
            )
            )
            }
            <tr className='totalBalance'><td></td><td>{this.getTotalBalance()}</td><td>מאזן כספים</td></tr>

          </tbody>
        </table>
        <Button disabled={isDisabled} variant={!isDisabled ? 'primary' : 'secondary'} onClick={() => this.submitData(false)} type='submit'>עדכון</Button>
        <div className='change-particiapnts'>
          <input className='participant-name' type="text" placeholder='הוספת/מחיקת משתמש' onChange={this.addOrRemoveUserInput} value={addOrRemoveUser} ></input>
          {warningMsg ? <p className='error'>{warningMsg}</p> : null}
          {successMsg ? <p className='success'>{successMsg}</p> : null}
          <div className='change-particiapnts-buttons-container'>
            <Button variant='danger' className='custom-danger-btn' onClick={() => this.addOrRemoveUser('remove')} >מחיקה</Button>
            <Button variant='success' className='custom-success-btn' onClick={() => this.addOrRemoveUser('add')}>הוספה</Button>
          </div>
        </div>
        <ul className='history-container'>
          {historyList.map((item) => (
            <li className="history-list" key={item._id}>{item.data}</li>
          ))}
        </ul>
      </>
    );
  };
}
export default Table;